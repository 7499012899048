import React, {useState,useEffect} from 'react';
import './styles.css'
import {Rating} from "semantic-ui-react";
import {ReactComponent as ArrowIcon} from "../../icons/arrow-profile-icon.svg";
import DoubleLineChart from "../../components/GraphComponent";
import {useSelector} from "react-redux";

export default function Profile(props) {
    const userData = useSelector(state => state.userLogin.userData);
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        const arrayTask = [];
        Object.values(userData.profiles.tasks.last_tasks).forEach((item, index) => {
            arrayTask.push(
                <div className='profile-task'>
                    <div
                        className='profile-task-number'
                        style={{
                            borderRadius: '10px 0 0 0',
                            backgroundColor: index % 2 === 0 ? '#F6FCFF' : '#EBF6FF'
                        }}>
                        №{item.id_order}
                    </div>
                    <div className='profile-task-text'>{item.product_name}</div>
                    <div className='profile-task-company'>{item.customer_name}</div>
                </div>
            )
        })
        setTasks(arrayTask);
    }, []);
            //console.log(userData)
            //console.log(Object.values(userData.profiles.tasks.last_tasks))
            return (
                <div className='profile-view'>
                    <h1 className='profile-title-h1'>Личный кабинет</h1>
                    <div className='profile-header'>
                        <div className='profile-icon-container'>
                            <img src={userData.profiles.avatar} alt='Аватар' className='profile-icon'></img>
                            <div className='profile-rating-conainer'>
                            <div className='profile-rating'>
                            <div className='profile-rating-text'>от клиентов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.customer} size='large'></Rating>
                        </div>
                        <div className='profile-rating'>
                            <div className='profile-rating-text'>от специалистов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.admin} size='large'></Rating>
                        </div>
                    </div>
                </div>
                <div className='profile-nav-container'>
                    <div className='profile-name'>{userData.first_name + ' ' + userData.last_name}</div>
                    <div className='profile-divider'></div>
                    <nav className='profile-nav'>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'>Редактировать профиль</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'>Оплата услуг</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'>Мои отзывы</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'>Календарь</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='profile-tasks'>
                <h2 className='profile-title-h2'>Последние активные задачи</h2>
                {tasks.length > 0 ?
                    <div className='profile-tasks-container'>
                        {tasks}
                    </div> : <div style={{marginLeft: "5px"}}>Нет задач</div>}
            </div>
            <div className='profile-stats'>
                <h2 className='profile-title-h2'>Статистика</h2>
                <div className='profile-stats-row'>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>{userData.profiles.tasks.totals_earn['2024-03'].total_in_work}</div>
                        <div className='profile-stats-text'>Задач в работе</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#EBF6FF'}}>{userData.profiles.tasks.totals_earn['2024-03'].total_closed}</div>
                        <div className='profile-stats-text'>Задач завершено</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>{userData.profiles.tasks.totals_earn['2024-03'].earned_sum}</div>
                        <div className='profile-stats-text'>Задач в работе</div>
                    </div>
                </div>
            </div>
            <div className='profile-graph'>
                <h2 className='profile-title-h2'>Задачи за период</h2>
                <DoubleLineChart/>
            </div>
        </div>
    )
}
